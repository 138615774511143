import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SharedFacade } from '../../shared.facade';

@Component({
    selector: 'app-cookie-consent',
    template: `
    <app-cookie-consent-ui [isCookiesAccepted]="isCookiesAccepted" (onAcceptCookies)="setAcceptCookies()">
    </app-cookie-consent-ui>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieConsentContainerComponent implements OnInit {
    isCookiesAccepted: boolean = true;

    constructor(private _sharedFacade: SharedFacade) { }

    ngOnInit() {
        this.isCookiesAccepted = this._sharedFacade.getAcceptCookies();
    }

    setAcceptCookies() {
        this.isCookiesAccepted = this._sharedFacade.setAcceptCookies();
    }
}
