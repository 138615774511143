import { IFeature } from 'src/app/map/models/feature';
import { IFeatureCollection } from 'src/app/map/models/feature-collection';
import { AddressLevel } from './address-level';
import { ICoordinate } from './coordinate';

export interface IAddress {
    id: string;
    // countryId: number;
    // regionId: number;
    // municipalityId: number;
    // parishId: number;

    // IBaseAddress
    // addressLevel: AddressLevel;
    // zipCode: number;
    // zipName: string;
    // roadName: string;
    // houseNumber: string;
    // floor: string;
    // door: string;
    // name: string;
    coordinate: ICoordinate;

    urlParameterZip: string;
    urlParameterRoad: string;
    urlParameterLevel: string;

    // isUrlParameterMatch(urlParameter: string): boolean;
}

export class Address implements IAddress {
    id: string;
    // countryId: number;
    // regionId: number;
    // municipalityId: number;
    // parishId: number;

    // addressLevel: AddressLevel;
    // zipCode: number;
    // zipName: string;
    // roadName: string;
    // houseNumber: string;
    // floor: string;
    // door: string;

    // name: string;
    coordinate: ICoordinate;

    urlParameterZip: string;
    urlParameterRoad: string;
    urlParameterLevel: string;

    constructor() {
        // this.addressLevel = AddressLevel.None;
    }

    // init(obj: IBaseAddress): void {
    //     // this.countryId = obj.countryId;
    //     // this.regionId = obj.regionId;
    //     // this.municipalityId = obj.municipalityId;
    //     // this.parishId = obj.parishId;

    //     this.addressLevel = obj.addressLevel;
    //     this.zipCode = obj.zipCode;
    //     this.zipName = obj.zipName;
    //     this.roadName = obj.roadName;
    //     this.houseNumber = obj.houseNumber;
    //     this.floor = obj.floor;
    //     this.door = obj.door;

    //     this.name = obj.name;
    //     this.coordinate = obj.coordinate;
    // }

    // get fullName() {
    //     return this.name;
    // }

    // isUrlParameterMatch(urlParameter: string): boolean {
    //     return urlParameter == Address.prepareUrlParameter(this.name);
    // }

    static getFeatureCollection(coordinate: ICoordinate): IFeatureCollection {
        return {
            type: 'FeatureCollection',
            features: [Address.getFeature(coordinate)],
        };
    }

    static getFeature(coordinate: ICoordinate): IFeature {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [coordinate.lng, coordinate.lat],
            },
        };
    }

    static getZipUrlParameter(zipCode: number, zipName: string): string {
        let zipNameEscaped = Address.getDashEscapedString(zipName);
        let param = `${zipCode}-${zipNameEscaped}`;

        return Address.prepareUrlParameter(param);
    }

    static getRoadUrlParameter(roadName: string, houseNumber: string): string {
        let roadNameEscaped = Address.getDashEscapedString(roadName);
        let param = `${roadNameEscaped}-${houseNumber}`;

        return Address.prepareUrlParameter(param);
    }

    static getLevelUrlParameter(floor: string, door: string): string {
        var doorEscaped = Address.getDashEscapedString(door); // Can contain "/" and "-"
        let param = `${floor}-${doorEscaped}`;

        // Handling missing data for param 3
        if (floor != null && doorEscaped == null) param = floor + '-';
        else if (floor == null && doorEscaped != null) param = '-' + doorEscaped;
        else if (floor == null && doorEscaped == null) param = null;

        return Address.prepareUrlParameter(param);
    }

    static prepareUrlParameter(value: string): string {
        let output: string = null;
        if (value != null) {
            output = value
                // .toLowerCase()
                .replace(/ /g, '-');
        }

        return output;
    }

    // If value contains "-" it needs to be replaced
    static getDashEscapedString(input: string): string {
        if (input) {
            var output = '';
            var inputParts = input.split('-');
            for (var i = 0; i < inputParts.length; i++) {
                output += (i > 0 ? '--' : '') + inputParts[i];
            }
            return output;
        } else {
            return null;
        }
    }
}
