import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home-ui',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
    constructor(private _router: Router) {
    }

    select(selected: any) {
        var route = 'kort';
        if (selected.zipParam == null && selected.roadParam == null)
            this._router.navigate([route, selected.regionParam, selected.municipalityParam, selected.parishParam]);
        else if (selected.levelParam == null)
            this._router.navigate([route, selected.regionParam, selected.municipalityParam, selected.parishParam, selected.zipParam, selected.roadParam]);
        else
            this._router.navigate([route, selected.regionParam, selected.municipalityParam, selected.parishParam, selected.zipParam, selected.roadParam, selected.levelParam]);
    }
}
