import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    { path: 'kort', loadChildren: () => import('./map/map.module').then(m => m.MapModule) },
    { path: 'om-ditomraade', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
    { path: 'analyse', loadChildren: () => import('./deep-dive/deep-dive.module').then(m => m.DeepDiveModule) },
    { path: 'roadmap', loadChildren: () => import('./roadmap/roadmap.module').then(m => m.RoadmapModule) },
    { path: 'kontakt', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
    { path: 'almindelige-vilkaar', loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule) },
    { path: 'cookiepolitik', loadChildren: () => import('./cookies/cookies.module').then(m => m.CookiesModule) },
    { path: 'datakilder', loadChildren: () => import('./data-sources/data-sources.module').then(m => m.DataSourcesModule) },
    { path: 'privatlivspolitik', loadChildren: () => import('./privacy-terms/privacy-terms.module').then(m => m.PrivacyTermsModule) },

    // Handle all other routes
    { path: '**', loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
