import { NgModule, Optional, SkipSelf } from '@angular/core';

import { GoogleAnalyticsService } from './services/google-analytics.service';
import { HotjarService } from './services/hotjar.service';
import { LocalStorageService } from './services/local-storage.service';
import { SentryService } from './services/sentry.service';

@NgModule({
    declarations: [],
    imports: [
    ],
    providers: [
        GoogleAnalyticsService,
        HotjarService,
        LocalStorageService,
        SentryService,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
        }
    }
}
