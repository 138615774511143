import { Injectable } from '@angular/core';
import { MenuState } from './state/menu.state';
import { IMenuItem } from './models/menu-item';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMenuSettings } from './models/menu-settings';
import { Router } from '@angular/router';

import { LocalStorageService } from '../core/services/local-storage.service';
import { AddressService } from './services/address.service';

@Injectable({
    providedIn: 'root',
})
export class SharedFacade {
    private _cookieAcceptName: string = 'cookies_accepted';
    private _cookieRenewDateName: string = 'cookies_accepted_date';
    private _cookieAcceptValue: string = 'yes';
    private _menuDesktopLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _menuState: MenuState,
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _addressService: AddressService
    ) {}

    getMenuDesktopLoaded$(): Observable<boolean> {
        return this._menuDesktopLoaded$.asObservable();
    }

    setMenuDesktopLoaded(): void {
        this._menuDesktopLoaded$.next(true);
    }

    getMenuSettings$(): Observable<IMenuSettings> {
        return this._menuState.getSettings$();
    }

    getVisibleMenuItems$(): Observable<IMenuItem[]> {
        return this._menuState.getVisibleMenuItems$();
    }

    getAddressDataFromAddressId$(id: string): Observable<any> {
        return this._addressService.getAddressDataFromAddressId$(id);
    }

    toggleSidebarActive(isActive: boolean): void {
        return this._menuState.toggleSidebarActive(isActive);
    }

    navigate(path: string[]) {
        this._router.navigate(path);
    }

    setAcceptCookies(): boolean {
        var currentDate = this.getCurrentDate();

        this._localStorageService.setItem(this._cookieAcceptName, this._cookieAcceptValue);
        this._localStorageService.setItem(this._cookieRenewDateName, currentDate.formatted);
        return this.getAcceptCookies();
    }

    getAcceptCookies(): boolean {
        let consent = this._localStorageService.getItem(this._cookieAcceptName);
        let consentDate = this.getConsentDate();
        var currentDate = this.getCurrentDate();
        var diff = Math.abs(currentDate.date.getTime() - consentDate.date.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        return consent == this._cookieAcceptValue && diffDays < 365;
    }

    private getConsentDate(): { day: number; month: number; year: number; formatted: string; date: Date } {
        let consentDate = this._localStorageService.getItem(this._cookieRenewDateName);
        if (consentDate == null) {
            consentDate = '1970-1-1';
        }

        var dateParts = consentDate.split('-');

        return {
            day: +dateParts[2],
            month: +dateParts[1],
            year: +dateParts[0],
            formatted: consentDate,
            date: this.getDate(+dateParts[2], +dateParts[1], +dateParts[0]),
        };
    }

    private getCurrentDate(): { day: number; month: number; year: number; formatted: string; date: Date } {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        return {
            day: dd,
            month: mm,
            year: yyyy,
            formatted: yyyy + '-' + mm + '-' + dd,
            date: today,
        };
    }

    private getDate(day: number, month: number, year: number): Date {
        return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
    }
}
