export const environment = {
    production: true,
    keys: {
        sentry: {
            isEnabled: true,
            appId: '1384019',
            key: '4037e4f696964850a94b6c70894441aa'
        },
        hotjar: {
            isEnabled: true,
            key: 926864
        },
        analytics: {
            isEnabled: true,
            key: 'UA-122094375-1'
        },
        tagManager: {
            isEnabled: true,
            key: 'GTM-NNBQHFQ'
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';