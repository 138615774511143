import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MenuMobileContainerComponent } from './components/menu-mobile/menu-mobile.container';
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { CookieConsentContainerComponent } from './components/cookie-consent/cookie-consent.container';

@NgModule({
    declarations: [
        MenuMobileContainerComponent,
        MenuMobileComponent,
        CookieConsentContainerComponent,
        CookieConsentComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
    exports: [
        MenuMobileContainerComponent,
        MenuMobileComponent,
        CookieConsentContainerComponent,
        CookieConsentComponent,

        CommonModule,
        RouterModule,
    ]
})
export class SharedCommonModule { }