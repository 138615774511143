import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DawaApiService {
    constructor(private _http: HttpClient) {}

    getDawaAddressInfoById(id: string, srid: number = 4326): Observable<any> {
        // Coordinates can be fetched in different formats by using "srid" parameter:
        // 4326: WGS 84 (http://epsg.io/4326)
        // 25832: ETRS89 / UTM zone 32N (http://epsg.io/25832)

        return this._http.get(`https://dawa.aws.dk/adresser/${id}?srid=${srid}`);
    }

    getDawaAddressInfoByAddress(zip: string, roadName: string, houseNumber: string, floor: string, door: string, srid: number = 4326): Observable<any> {
        // Coordinates can be fetched in different formats by using "srid" parameter:
        // 4326: WGS 84 (http://epsg.io/4326)
        // 25832: ETRS89 / UTM zone 32N (http://epsg.io/25832)
        // if (srid == null)
        //     srid = 4326;

        return this._http
            .get(`https://dawa.aws.dk/adresser?postnr=${zip}&vejnavn=${roadName}&husnr=${houseNumber}&etage=${floor}&dør=${door}&srid=${srid}`); // &struktur=mini
    }
}
