import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { IMenuItem } from '../../models/menu-item';
import { IMenuSettings } from '../../models/menu-settings';

declare var $: any;

@Component({
    selector: 'app-menu-mobile-ui',
    templateUrl: './menu-mobile.component.html',
    styleUrls: ['./menu-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class MenuMobileComponent implements OnInit, OnChanges {
    @Input() menuDesktopLoaded: { loaded: boolean };
    @Input() settings: IMenuSettings;
    @Input() items: IMenuItem[];
    @Output() toggleSidebarActive: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.menuDesktopLoaded && false === changes.menuDesktopLoaded.firstChange) {
            if (this.menuDesktopLoaded.loaded === true) {
                this.initiateSidebar();
            }
        }
    }

    initiateSidebar() {
        var self = this;

        // Reenable visibility after page loaded
        $('#menu-mobile').css('visibility', 'visible');

        $('#menu-mobile').sidebar({
            dimPage: true,
            transition: 'overlay',
            mobileTransition: 'overlay',
            exclusive: true,
            closable: true,
            onVisible: function () {
                self.toggleSidebarActive.emit(true);
                // $('.pusher').css('overflow', '');
            },
            onHide: function () {
                self.toggleSidebarActive.emit(false);
            },
            onHidden: function () {
                // $('.pusher').css('overflow', 'initial');
            },
        });

        $('#menu-mobile').sidebar('attach events', '#menu-mobile-toggle');

        // Initially set overflow to initial:
        // https://github.com/Semantic-Org/Semantic-UI/issues/5827
        // $('.pusher').css('overflow', 'initial');
    }

    clickMenuItem() {
        $('#menu-mobile').sidebar('toggle');
    }
}
