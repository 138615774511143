import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from '../../../environments/environment';

import * as Sentry from "@sentry/browser";

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
    constructor() {
        if (environment.keys.sentry.isEnabled) {
            Sentry.init({
                dsn: `https://${environment.keys.sentry.key}@sentry.io/${environment.keys.sentry.appId}`
            });
        }
    }
    handleError(error: any) {
        if (environment.keys.sentry.isEnabled) {
            Sentry.captureException(error.originalError || error);
        }
        throw error;
    }
}