import { Action, createReducer, on } from '@ngrx/store';
import * as MenuActions from './menu.actions';
import { IMenuItem, MenuItem } from '../../models/menu-item';
import { IMenuSettings } from '../../models/menu-settings';
import { MenuLayout } from '../../models/menu-layout';
import { MenuItemPosition } from '../../models/menu-item-position';

export const menuFeatureKey = 'menu';

export interface State {
    settings: IMenuSettings;
    items: IMenuItem[];
}

export const initialState: State = {
    settings: {
        layout: MenuLayout.Responsive,
        overlayed: false,
        searchEnabled: false,
        sidebarActive: false,
    },
    items: [
        {
            title: 'Forside',
            link: '/',
            active: true,
            visible: false,
            position: MenuItemPosition.Left,

            overlayed: true,
            layout: MenuLayout.Responsive,
        },
        {
            title: 'Kort',
            link: '/kort',
            active: false,
            visible: true,
            position: MenuItemPosition.Left,

            layout: MenuLayout.Fixed,
            searchEnabled: true,
        },
        {
            title: 'Kommuner',
            link: '/analyse/kommuner',
            active: false,
            visible: true,
            position: MenuItemPosition.Left,

            layout: MenuLayout.Fixed,
        },
        // {
        //     title: "Roadmap",
        //     link: "/roadmap",
        //     active: false,
        //     visible: true,
        //     position: MenuItemPosition.Left
        // },
        // {
        //     title: "Om DitOmraade",
        //     link: "/om-ditomraade",
        //     active: false,
        //     visible: true,
        //     position: MenuItemPosition.Left
        // },
        // {
        //     title: "Konto",
        //     link: "/log-ind"
        // }
    ],
};

const menuReducer = createReducer(
    initialState,

    on(MenuActions.setActive, (state, action) => {
        // Menu items
        let items = state.items.map((x) => MenuItem.withActive(x, action.url));

        // Settings
        let activeItem = items.find((x) => x.active);
        let settings = {
            ...state.settings,
            layout: activeItem !== undefined && activeItem.layout !== undefined ? activeItem.layout : initialState.settings.layout,
            overlayed:
                activeItem !== undefined && activeItem.overlayed !== undefined ? activeItem.overlayed : initialState.settings.overlayed,
            searchEnabled:
                activeItem !== undefined && activeItem.searchEnabled !== undefined
                    ? activeItem.searchEnabled
                    : initialState.settings.searchEnabled,
        };

        return {
            ...state,
            settings: settings,
            items: items,
        };
    }),

    on(MenuActions.setSidebarActive, (state, action) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                sidebarActive: !state.settings.sidebarActive,
            },
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return menuReducer(state, action);
}
