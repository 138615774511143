import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedFacade } from '../../shared.facade';

@Component({
    selector: 'app-address-search',
    template: ` <app-address-search-ui [size]="size" [showIcon]="showIcon" (onSelect)="select($event)"> </app-address-search-ui> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchContainerComponent implements OnInit {
    @Input() size: string;
    @Input() showIcon: boolean;

    @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

    constructor(private _sharedFacade: SharedFacade) {}

    ngOnInit(): void {}

    select(addressId: string) {
        this._sharedFacade.getAddressDataFromAddressId$(addressId).subscribe((x) => {
            this.onSelect.emit(x);
        });
    }
}
