<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu-desktop></app-menu-desktop>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui inverted vertical masthead segment">
            <div class="b-overlay"></div>
            <div class="ui grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <div class="b-header-container">
                            <img src="/assets/logo_with_name.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui vertical stripe segment">
            <div class="ui stackable divided center aligned grid container">
                <div class="row">
                    <div class="column">
                        <h1 class="b-header-large ui header">Start din søgning her...</h1>

                        <div class="b-address-search-container ui raised segment">
                            <app-address-search (onSelect)="select($event)" [size]="'massive'" [showIcon]="true"></app-address-search>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="ui vertical stripe segment">
            <div class="ui stackable two column divided center aligned grid container">
                <div class="row">
                    <div class="column">
                        <h1 class="b-header-large b-2nd-row ui header">...eller udforsk hele Danmark</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <h2 class="ui center aligned icon header">
                            <i class="circular map marker alternate icon"></i>
                            Kort
                        </h2>
                        <p>
                            Få et overblik over dit nuværende eller kommende nærområde mht. uddannelse, børnepasning,
                            indkøbsmuligheder, sportsfaciliteter, offentlig transport, lokalplaner og meget andet.
                        </p>
                        <a class="ui huge button" routerLink="/kort">Se kort her</a>
                    </div>
                    <div class="column">
                        <h2 class="ui center aligned icon header">
                            <i class="circular users icon"></i>
                            Kommune
                        </h2>
                        <p>
                            Gå på opdagelse i din nuværende eller kommende kommune ud fra sociale, økonomiske, demografiske, miljø- og
                            uddannelsesmæssige statistikker.
                        </p>
                        <a class="ui huge button" routerLink="/analyse/kommuner">Se kommuner her</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <div class="appfooter">
            <app-footer></app-footer>
        </div>
    </div>
</div>
