import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as MenuActions from './menu.actions';
import * as fromRouter from '@ngrx/router-store';

@Injectable()
export class MenuEffects {
    constructor(private actions$: Actions) { }

    loadMenus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromRouter.routerNavigationAction),
            concatMap(x => {
                // Change active menu item as result of routeNavigationAction
                return of(MenuActions.setActive({ url: x.payload.event.url }))
            })
        );
    });
}
