import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { SharedFacade } from '../../shared.facade';
import { Observable } from 'rxjs';
import { IMenuItem } from '../../models/menu-item';
import { IMenuSettings } from '../../models/menu-settings';
import { map } from 'rxjs/operators';
import { MenuItemPosition } from '../../models/menu-item-position';

@Component({
    selector: 'app-menu-desktop',
    template: `
        <app-menu-desktop-ui
            [settings]="settings$ | async"
            [itemsLeft]="itemsLeft$ | async"
            [itemsRight]="itemsRight$ | async"
            (selectAddress)="selectAddress($event)"
        >
        </app-menu-desktop-ui>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDesktopContainerComponent implements OnInit, OnDestroy, AfterViewInit {
    settings$: Observable<IMenuSettings>;
    itemsLeft$: Observable<IMenuItem[]>;
    itemsRight$: Observable<IMenuItem[]>;

    constructor(private _sharedFacade: SharedFacade) {}

    ngOnInit() {
        this.settings$ = this._sharedFacade.getMenuSettings$();
        this.itemsLeft$ = this._sharedFacade.getVisibleMenuItems$().pipe(map((x) => x.filter((y) => y.position === MenuItemPosition.Left)));
        this.itemsRight$ = this._sharedFacade
            .getVisibleMenuItems$()
            .pipe(map((x) => x.filter((y) => y.position === MenuItemPosition.Right)));
    }

    ngAfterViewInit(): void {
        this._sharedFacade.setMenuDesktopLoaded();
    }

    ngOnDestroy(): void {}

    selectAddress(selected: any) {
        var route = 'kort';
        if (selected.zipParam == null && selected.roadParam == null)
            this._sharedFacade.navigate([route, selected.regionParam, selected.municipalityParam, selected.parishParam]);
        else if (selected.levelParam == null)
            this._sharedFacade.navigate([
                route,
                selected.regionParam,
                selected.municipalityParam,
                selected.parishParam,
                selected.zipParam,
                selected.roadParam,
            ]);
        else
            this._sharedFacade.navigate([
                route,
                selected.regionParam,
                selected.municipalityParam,
                selected.parishParam,
                selected.zipParam,
                selected.roadParam,
                selected.levelParam,
            ]);
    }
}
