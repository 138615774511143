import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import * as Sentry from "@sentry/browser";

@Injectable()
export class SentryService {
    constructor() { }

    captureException(exception: any) {
        if (environment.keys.sentry.isEnabled) {
            Sentry.captureException(exception);
        }
    }
}
