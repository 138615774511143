
<div class="ui inverted vertical footer segment">
    <div class="ui container">
        <div class="ui stackable inverted divided equal height stackable grid">
            <div class="four wide column">
                <h4 class="ui inverted header">Boligder ApS</h4>
                <p>
                    Boligder ApS<br />
                    Ejbydalsvej 91<br />
                    2600 Glostrup<br />
                    CVR: 39699524<br />
                    E-mail: <a href="mailto:info@boligder.dk">info@boligder.dk</a>
                </p>
            </div>
            <div class="four wide column">
                <h4 class="ui inverted header">Om ditomraade.dk</h4>
                <div class="ui inverted link list">
                    <a class="item" routerLink="/kontakt">Kontakt</a>
                    <a class="item" routerLink="/datakilder">Hvor kommer data fra?</a>
                </div>
            </div>
            <div class="four wide column">
                <h4 class="ui inverted header">Vilkår og betingelser</h4>
                <div class="ui inverted link list">
                    <a class="item" routerLink="/almindelige-vilkaar">Almindelige vilkår</a>
                    <a class="item" routerLink="/cookiepolitik">Cookiepolitik</a>
                    <a class="item" routerLink="/privatlivspolitik">Privatlivspolitik</a>
                </div>
            </div>
            <div class="four wide column">
                <h4 class="ui inverted header">Følg os på</h4>
                <div class="ui inverted link list">
                    <a class="item" href="https://www.facebook.com/ditomraade" target="_blank"><img src="/assets/facebook.jpg" />&nbsp;&nbsp;&nbsp;Facebook</a>
                    <a class="item" href="https://www.instagram.com/ditomraade.dk/" target="_blank"><img src="/assets/instagram.png" />&nbsp;&nbsp;&nbsp;Instagram</a>
                </div>
            </div>
        </div>
    </div>
</div>
