import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { IMenuItem } from '../../models/menu-item';
import { IMenuSettings } from '../../models/menu-settings';

@Component({
    selector: 'app-menu-desktop-ui',
    templateUrl: './menu-desktop.component.html',
    styleUrls: ['./menu-desktop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuDesktopComponent {
    @Input() settings: IMenuSettings;
    @Input() itemsLeft: IMenuItem[];
    @Input() itemsRight: IMenuItem[];
    @Output() selectAddress: EventEmitter<string[]> = new EventEmitter<string[]>();

    constructor() { }
    
    select(selected: any) {
        this.selectAddress.emit(selected);
    }
}
