import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as dawaAutocompleteModule from 'dawa-autocomplete2';

@Component({
    selector: 'app-address-search-ui',
    templateUrl: './address-search.component.html',
    styleUrls: ['./address-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AddressSearchComponent implements OnInit {
    @Input() size: string;
    @Input() showIcon: boolean;

    @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

    icon: string;
    searchInst: any;
    searchStr: string = null;

    constructor(private _ngZone: NgZone) {
        window['angularComponentRef'] = { component: this, zone: _ngZone };
    }

    ngOnInit() {
        if (this.showIcon) {
            this.icon = 'icon';
        }
    }

    ngAfterViewInit() {
        // Dawa
        var inputElem = document.getElementById('dawa-autocomplete-input');
        var optn = {
            select: (selected: any) => {
                window['angularComponentRef'].zone.run(() => {
                    this.select(selected);
                });
            },
        };
        this.searchInst = dawaAutocompleteModule.dawaAutocomplete(inputElem, optn);
    }

    ngOnDestroy() {
        window['angularComponentRef'] = null;
    }

    select(selected: any) {
        this.onSelect.emit(selected.data.id);
    }
}
