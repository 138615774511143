import { Injectable } from '@angular/core';
import { DeviceType } from '../models/device-type';
import { IDeviceDimensions } from '../models/device-dimensions';

@Injectable()
export class UtilityService {
    constructor() { }

    getClientDeviceDimensions(): IDeviceDimensions {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        return {
            width: w,
            height: h
        }
    }

    getClientDeviceType(): DeviceType {
        let clientDeviceDimensions = this.getClientDeviceDimensions();
        if (clientDeviceDimensions.width < 768) {
            return DeviceType.Mobile;
        }
        else {
            return DeviceType.Desktop;
        }
    }

    getRandomString(): string {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return '_' + Math.random().toString(36).substr(2, 9);
    };
}
