import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    template: `
    <app-home-ui>
    </app-home-ui>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeContainerComponent implements OnInit {
    constructor() { }

    ngOnInit(): void  {
    }
}
