import { MenuItemPosition } from './menu-item-position';
import { MenuLayout } from './menu-layout';

export interface IMenuItem {
    title: string;
    link: string;
    active: boolean;
    visible: boolean;
    position: MenuItemPosition;

    overlayed?: boolean;
    layout?: MenuLayout;
    searchEnabled?: boolean;
}

export class MenuItem implements IMenuItem {
    title: string;
    link: string;
    active: boolean;
    visible: boolean;
    position: MenuItemPosition;

    overlayed: boolean = false;
    layout: MenuLayout = MenuLayout.FullWidth;
    searchEnabled: boolean = false;

    static withActive(item: IMenuItem, url: string): IMenuItem {
        return {
            ...item,
            active: (item.link == url || (url.startsWith(item.link) && item.link !== '/'))
        }
    }
}