<div class="b-menu-container-desktop" [ngClass]="{'responsive' : settings.layout == 1, 'fixed' : settings.layout == 3, 'overlayed' : settings.overlayed}">
    <div class="ui top inverted blue container menu desktop" [ngClass]="{'responsive' : settings.layout == 1, 'fullwidth' : settings.layout == 2, 'fixed' : settings.layout == 3, 'overlayed' : settings.overlayed}">
            <!-- <app-feedback></app-feedback> -->
            <div class="ui container">

            <a class="header item" routerLink="/">
                <img src="/assets/logo_white.svg" />
                DitOmråde
            </a>

            <a class="item" [ngClass]="{'active' : item.active}" *ngFor="let item of itemsLeft" [routerLink]="item.link">
                <div class="content">
                    {{item?.title}}
                </div>
            </a>

            <div class="ui category search item" *ngIf="settings.searchEnabled">
                <div class="ui icon input">
                    <app-address-search (onSelect)="select($event)" style="width:300px" [showIcon]="true"></app-address-search>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="b-menu-container-phone">
    <div class="ui top inverted blue fixed menu phone">
        <div class="ui container">
            <!-- <app-feedback></app-feedback> -->
                
            <a class="item" id="menu-mobile-toggle">
                <i class="bars icon" *ngIf="!settings.sidebarActive"></i>
                <i class="close icon" *ngIf="settings.sidebarActive"></i>
            </a>

            <div class="menu right secondary">
                <a class="header item" routerLink="/">
                    <img src="/assets/logo_white.svg" />
                </a>
            </div>
        </div>
    </div>
</div>