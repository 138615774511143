import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

/* Locale */
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
registerLocaleData(localeDa, 'da');

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeModule } from './home/home.module';
import { CoreModule } from './core/core.module';
import { SharedCommonModule } from './shared/shared.common-module';
import { MenuMobileContainerComponent } from './shared/components/menu-mobile/menu-mobile.container';
import { CookieConsentContainerComponent } from './shared/components/cookie-consent/cookie-consent.container';
import { SentryErrorHandlerService } from './core/handlers/sentry-error-handler.service';
import { CustomRouterStateSerializer } from './core/serializers/custom-router-state-serializer';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './core/handlers/custom-route-reuse-strategy.handler';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CoreModule,
        SharedCommonModule,

        AppRoutingModule,
        StoreModule.forRoot({
            router: routerReducer
        }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, //  Retains last 25 states
            logOnly: environment.production
        }),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomRouterStateSerializer,
        }),

        HomeModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'da'
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandlerService
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
    ],
    bootstrap: [AppComponent, MenuMobileContainerComponent, CookieConsentContainerComponent]
})
export class AppModule { }
