import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromMenu from './menu/menu.selectors';
import * as menuActions from './menu/menu.actions';
import { IMenuItem } from '../models/menu-item';
import { IMenuSettings } from '../models/menu-settings';

@Injectable({
    providedIn: 'root',
})
export class MenuState {
    constructor(private _store: Store<any>) {}

    getSettings$(): Observable<IMenuSettings> {
        return this._store.pipe(select(fromMenu.selectSettings));
    }

    getVisibleMenuItems$(): Observable<IMenuItem[]> {
        return this._store.pipe(select(fromMenu.selectActiveMenuItems));
    }

    toggleSidebarActive(isActive: boolean): void {
        this._store.dispatch(menuActions.setSidebarActive({ isActive }));
    }
}
