import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SharedFacade } from '../../shared.facade';
import { Observable } from 'rxjs';
import { IMenuItem } from '../../models/menu-item';
import { IMenuSettings } from '../../models/menu-settings';
import { tap } from 'rxjs/internal/operators/tap';
import { map } from 'rxjs/operators';

@Component({
    selector: '[app-menu-mobile]',
    template: `
        <app-menu-mobile-ui
            [settings]="settings$ | async"
            [items]="items$ | async"
            [menuDesktopLoaded]="menuDesktopLoaded$ | async"
            (toggleSidebarActive)="toggleSidebarActive($event)"
        >
        </app-menu-mobile-ui>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuMobileContainerComponent implements OnInit {
    menuDesktopLoaded$: Observable<{ loaded: boolean }>;
    settings$: Observable<IMenuSettings>;
    items$: Observable<IMenuItem[]>;

    constructor(private _sharedFacade: SharedFacade) {}

    ngOnInit() {
        this.menuDesktopLoaded$ = this._sharedFacade.getMenuDesktopLoaded$().pipe(
            map((x) => {
                // Need this, since else the data is not getting updated on the ui-component, when value is unchanged
                return { loaded: x };
            })
        );
        this.settings$ = this._sharedFacade.getMenuSettings$();
        this.items$ = this._sharedFacade.getVisibleMenuItems$();
    }

    toggleSidebarActive(isActive: boolean): void {
        this._sharedFacade.toggleSidebarActive(isActive);
    }
}
