import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-cookie-consent-ui',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieConsentComponent {
    @Input() isCookiesAccepted: boolean = true;
    @Output() onAcceptCookies: EventEmitter<void> = new EventEmitter<void>();

    clickAcceptCookies() {
        this.onAcceptCookies.emit();;
    }
}
