import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseArea } from 'src/app/map/models/base-area';
import { DawaApiService } from '../dataaccess/dawa-api.service';
import { Address, IAddress } from '../models/address';
import { AddressLevel } from '../models/address-level';
import { ICoordinate } from '../models/coordinate';

@Injectable()
export class AddressService {
    constructor(private _dawaApiService: DawaApiService) {}

    getAddressDataFromAddressId$(id: string): Observable<any> {
        return this._dawaApiService.getDawaAddressInfoById(id).pipe(map((x) => this.mapAddressResponse(x)));
    }

    getAddressDataFromRouteData$(zipUrlParameter: string, roadUrlParameter: string, levelUrlParameter: string): Observable<IAddress> {
        var zipCode = this.getZipCode(zipUrlParameter);
        var roadName = this.getRoadName(roadUrlParameter);
        var houseNumber = this.getHouseNumber(roadUrlParameter);
        var floor = this.getFloor(levelUrlParameter);
        var door = this.getDoor(levelUrlParameter);

        return this._dawaApiService.getDawaAddressInfoByAddress(zipCode, roadName, houseNumber, floor || '', door || '').pipe(
            map((x) => {
                // When searching for address, multiple matches are possible
                // TODO: Error handling, when there are no matches (someone might fiddle with the url parameters to make a FAKE address)
                // TODO: Investigate if we can actually have more than 1 element in the array, if the input address is "correct"/conform.
                var firstAddress = x[0];
                let output = <IAddress>{
                    id: firstAddress.id,
                    urlParameterZip: zipUrlParameter,
                    urlParameterRoad: roadUrlParameter,
                    urlParameterLevel: levelUrlParameter,
                    coordinate : <ICoordinate>{
                        lng: firstAddress.adgangsadresse.adgangspunkt.koordinater[0],
                        lat: firstAddress.adgangsadresse.adgangspunkt.koordinater[1],
                    }
                };

                return output;
            })
        );
    }

    private mapAddressResponse(response: any): any {
        let region = response.adgangsadresse.region.navn.replace('Region ', '');
        let municipality = response.adgangsadresse.kommune.navn;
        let parish = response.adgangsadresse.sogn.navn;

        // Change region for Bornholms municipality to "Bornholm"
        if (response.adgangsadresse.kommune.navn === 'Bornholm') region = 'Bornholm';

        let zipCode = response.adgangsadresse.postnummer.nr;
        let zipName = response.adgangsadresse.postnummer.navn;
        let roadName = response.adgangsadresse.vejstykke.navn;
        let houseNumber = response.adgangsadresse.husnr;
        let floor = response.etage;
        let door = response.dør;

        let addressLevel = AddressLevel.None;
        if (zipCode && zipName && roadName && houseNumber && !floor && !door) addressLevel = AddressLevel.Road;
        else if (zipCode && zipName && roadName && houseNumber && floor && door) addressLevel = AddressLevel.Level;

        let regionUrlParameter = BaseArea.getUrlParameter(region);
        let municipalityUrlParameter = BaseArea.getUrlParameter(municipality);
        let parishUrlParameter = BaseArea.getUrlParameter(parish);
        let zipUrlParameter = Address.getZipUrlParameter(zipCode, zipName);
        let roadUrlParameter = Address.getRoadUrlParameter(roadName, houseNumber);
        let levelUrlParameter = Address.getLevelUrlParameter(floor, door);

        return {
            regionParam: regionUrlParameter,
            municipalityParam: municipalityUrlParameter,
            parishParam: parishUrlParameter,
            zipParam: zipUrlParameter,
            roadParam: roadUrlParameter,
            levelParam: levelUrlParameter,
        };
    }

    private getZipCode(zip: string): string {
        return zip.split('-', 1)[0];
    }

    private getRoadName(address: string): string {
        address = address.split('--').join('|'); // Replacing '--' with '|' since we need to split by '-' later. "-" in addresses are replaced by "--" (eg. 'C--Vej-1' and 'Slap--a--vej-1') whereas ' ' are replaced by '-' (eg. 'Vigerslev-Allé-23')
        var addressParts = address.split('-');
        addressParts.splice(addressParts.length - 1, 1); // Remove the number part

        return addressParts
            .join(' ') // Replacing all '-'  with ' '
            .split('|')
            .join('-'); // Replacing all '|' with '-'

        // === NB ===
        // //  Using RegEx with negative lookbehind is not supported in other browsers than Chrome, therefore it should not be used.
        // var addressParts = address.split(/(?<!-)-(?!-)/);   // "-" in addresses are replaced by "--" (eg. 'C--Vej-1' and 'Slap--a--vej-1') whereas ' ' are replaced by '-' (eg. 'Vigerslev-Allé-23')
        // addressParts.splice(addressParts.length - 1, 1);    // Remove the number part

        // return addressParts
        //     .join(' ')              // Replacing all '-'  with ' '
        //     .split('--').join('-'); // Replacing all '--' with '-'
    }

    private getHouseNumber(address: string): string {
        var addressParts = address.split('-');
        return addressParts[addressParts.length - 1];
    }

    private getFloor(level: string): string {
        if (level) return level.split('-')[0];
        else return null;
    }

    private getDoor(level: string): string {
        if (level) {
            level = level.split('--').join('|'); // Replacing '--' with '|' since we need to split by '-' later. "-" in door are replaced by "--" (eg. '22--2') whereas ' ' are replaced by '-'
            var levelParts = level.split('-');
            levelParts.splice(0, 1); // Remove the floor part

            return levelParts
                .join(' ') // Replacing all '-'  with ' '
                .split('|')
                .join('-'); // Replacing all '|' with '-'
        } else {
            return null;
        }
    }
}
