import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HomeRoutingModule } from './home.routing-module';

import { HomeComponent } from './components/home/home.component';
import { HomeContainerComponent } from './components/home/home.container';

import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        HomeComponent,
        HomeContainerComponent
    ],
    imports: [
        FormsModule,
        SharedModule,

        HomeRoutingModule
    ]
})
export class HomeModule { }
