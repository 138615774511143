import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedCommonModule } from './shared.common-module';
import { HttpClientModule } from '@angular/common/http';
import { UtilityService } from './services/utility.service';
import { ScrollService } from './services/scroll.service';
import { DawaApiService } from './dataaccess/dawa-api.service';

import { MenuDesktopComponent } from './components/menu-desktop/menu-desktop.component';
import { MenuDesktopContainerComponent } from './components/menu-desktop/menu-desktop.container';
import { FooterComponent } from './components/footer/footer.component';
import { TextWithTooltipComponent } from './components/text-with-tooltip/text-with-tooltip.component';
import { AddressSearchContainerComponent } from './components/address-search/address-search.container';
import { AddressSearchComponent } from './components/address-search/address-search.component';

import * as fromMenu from './state/menu/menu.reducer';
import { MenuEffects } from './state/menu/menu.effects';
import { FormsModule } from '@angular/forms';
import { AddressService } from './services/address.service';

@NgModule({
    declarations: [
        MenuDesktopContainerComponent,
        MenuDesktopComponent,
        FooterComponent,
        TextWithTooltipComponent,
        AddressSearchContainerComponent,
        AddressSearchComponent,
        // AddressSearchBoxComponent,
        // FeedbackComponent
    ],
    imports: [
        SharedCommonModule,
        FormsModule,
        StoreModule.forFeature(fromMenu.menuFeatureKey, fromMenu.reducer),
        EffectsModule.forFeature([MenuEffects])
    ],
    exports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        // FormsModule,

        MenuDesktopContainerComponent,
        MenuDesktopComponent,
        FooterComponent,
        TextWithTooltipComponent,
        AddressSearchContainerComponent,
        AddressSearchComponent,
        // AddressSearchBoxComponent,
        // FeedbackComponent
    ],
    providers: [
        UtilityService,
        ScrollService,
        AddressService,
        
        DawaApiService
    ]
})
export class SharedModule { }
