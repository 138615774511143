import { AreaLevel } from '../../shared/models/area-level';

export interface IBaseArea {
    id: number;
    name: string;
    areaLevel: AreaLevel;
    urlParameter: string;

    isUrlParameterMatch(urlParameter: string): boolean;
}

export abstract class BaseArea {
    id: number;
    name: string;

    get urlParameter(): string {
        return BaseArea.getUrlParameter(this.name);
    }

    public isUrlParameterMatch(urlParameter: string): boolean {
        return urlParameter == this.urlParameter;
    }

    static replaceWithLowerCase(value: string): string {
        let output = value
            .toLowerCase()

        return output;
    }

    static replaceDanishLetters(value: string): string {
        let output = value
            .replace('æ', 'ae')
            .replace('ø', 'oe')
            .replace('å', 'aa')
            .replace('Æ', 'AE')
            .replace('Ø', 'OE')
            .replace('Å', 'AA');

        return output;
    }

    static replaceSpace(value: string): string {
        return value
            .replace(' ', '-');
    }

    static getUrlParameter(name: string): string {
        let output: string = null;
        output = BaseArea.replaceWithLowerCase(name);
        output = BaseArea.replaceDanishLetters(output);
        output = BaseArea.replaceSpace(output);

        return output;
    }
}